import React, { useEffect, useState, useContext } from "react";
import { Page, FormSectionCard } from "../layout/page";
import { Calendar } from "@mantine/dates";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../context/language";
import {
  Group,
  Indicator,
  Table,
  Text,
  Box,
  Grid,
  Tabs,
  ScrollArea,
  Button,
  UnstyledButton,
} from "@mantine/core";
import moment from "moment";
import _ from "lodash";

import { useCellRender, openWhatsapp } from "../hooks/useCellRender";
import {
  IconBrandWhatsapp,
  IconCalendar,
  IconClock,
  IconPhone,
  IconExternalLink,
  IconMap,
} from "@tabler/icons";
import { useServerApi } from "../hooks/userServerApi";
import { useAuthUser } from "react-auth-kit";

export const getEventColor = (type) => {
  switch (type) {
    case "companyEvent":
      return "red";
    case "inTraining":
      return "yellow";
    case "meeting":
      return "orange";
    case "birthday":
      return "cyan";
    default:
      return "blue";
  }
};

const EventCalendar = ({ events, onMonthChange }) => {
  const eventDates = events?.map((e) => moment(e.date)) ?? [];
  const { t } = useTranslation();

  const isEventDate = (date) => {
    if (moment().isSame(date, "date")) return true;
    for (const d of eventDates) {
      if (d?.isSame(date, "date")) return true;
    }
    if (eventDates.include(date.get)) return false;
  };

  return (
    <div style={{ width: "100%" }}>
      <Calendar
        w={"100%"}
        onMonthChange={onMonthChange}
        onChange={() => {}}
        renderDay={(date) => {
          const day = date.getDate();
          const event = events?.find((e) =>
            moment(e.date).isSame(date.toISOString(), "date")
          );
          const isToday = moment().isSame(date.toISOString(), "date");
          return (
            <>
              {event && (
                <Indicator size={6} color={getEventColor(event.type)}>
                  <div>{day}</div>
                </Indicator>
              )}
              {!event && isToday && (
                <Indicator size={6} color="teal">
                  <div>{day}</div>
                </Indicator>
              )}
              {!event && !isToday && <div>{day}</div>}
            </>
          );
        }}
      />
      <Box mt={"xl"}>
        <Group>
          <Indicator size={6} color="teal" />
          <Text size="xs">{t("Today")}</Text>
        </Group>
        <Group noWrap>
          <Indicator size={6} color="red" />
          <Text size="xs">{t("Company Event")}</Text>
        </Group>
        <Group>
          <Indicator size={6} color="yellow" />
          <Text size="xs">{t("Internal Training")}</Text>
        </Group>
        <Group>
          <Indicator size={6} color="orange" />
          <Text size="xs">{t("Meeting")}</Text>
        </Group>
        <Group>
          <Indicator size={6} color="cyan" />
          <Text size="xs">{t("Client Birthday")}</Text>
        </Group>
      </Box>
    </div>
  );
};
const EventSection = ({
  title,
  txtSize = "xs",
  headerSize = "xs",
  iconSize = 12,
  ...props
}) => {
  const [cellRender] = useCellRender();
  const [events, setEvents] = useState([]);
  const [clients, setClients] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [api] = useServerApi();
  const { t } = useTranslation();

  const auth = useAuthUser();

  const fetchEvent = async () => {
    const e = await api.CalendarEvent.getByMonth(currentDate);
    // console.log("FetchEvent", e);
    // console.log("auth", auth());
    if (auth().id) {
      const result = await api.Client.getBirthdays(auth().id, currentDate);
      // console.log("result", result);
      if (result.data && result.data.clients) {
        const { clients } = result.data;
        if (!_.isEmpty(clients)) {
          clients.forEach((c) =>
            e.push({
              date: moment(c.birthday),
              title: `${t("Client Birthday")} ${moment
                .localeData()
                .ordinal(c.age)}`,
              type: "birthday",
              venues: (
                <Group noWrap position="apart">
                  <a
                    href={`/consultant/client?id=${c._id}`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    {`${c.name} ${c.cname}`}
                  </a>
                  <UnstyledButton onClick={() => openWhatsapp(c.phone)}>
                    <Group spacing={3} noWrap>
                      <IconBrandWhatsapp size={10}></IconBrandWhatsapp>
                      <Text size="xs" color={"dimmed"}>
                        {c.phone}
                      </Text>
                    </Group>
                  </UnstyledButton>
                </Group>
              ),
            })
          );
        }
      }
    }

    setEvents(e);
  };

  useEffect(() => {
    fetchEvent();
  }, [currentDate]);

  const rows = events?.map((ev) => (
    <tr key={ev._id}>
      <td>
        <Group noWrap>
          <IconCalendar size={iconSize} />
          <Text size={txtSize}>{moment(ev.date).format("LL")}</Text>
        </Group>
      </td>

      <td>
        <Group noWrap>
          <Indicator size={6} color={getEventColor(ev.type)} zIndex={1} />
        </Group>
      </td>

      <td>
        <Group noWrap>
          <Text size={txtSize} lineClamp={1}>
            {ev.title}
          </Text>
        </Group>
        <Group noWrap>
          {/* <IconMap size={iconSize}></IconMap> */}
          <Text size={"xs"} color="dimmed">
            {ev.venues}
          </Text>
        </Group>
      </td>
      <td>
        <Group noWrap>
          {ev.fromTime && <IconClock size={iconSize}></IconClock>}
          <Text size={txtSize}>
            {ev.fromTime ? moment(ev.fromTime).format("HH:mm") : ""}
            {ev.toTime ? ` - ${moment(ev.toTime).format("HH:mm")}` : ""}
          </Text>
        </Group>
      </td>

      {/* <td>{ev.description}</td> */}
    </tr>
  ));

  return (
    <FormSectionCard title={title} {...props}>
      <Grid columns={20}>
        <Grid.Col span={14}>
          <ScrollArea h={"450px"} type="auto">
            <Table>
              <thead>
                <tr>
                  <th>
                    <Text size={headerSize}>{t("Date")} </Text>
                  </th>

                  <th>
                    <Text size={headerSize}>{t("Type")} </Text>
                  </th>
                  <th>
                    <Text size={headerSize}>{t("Event")}</Text>
                  </th>
                  <th>
                    <Text size={headerSize}>{t("Duration")} </Text>
                  </th>

                  <th></th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
        <Grid.Col span={5}>
          <EventCalendar events={events} onMonthChange={setCurrentDate} />
        </Grid.Col>
      </Grid>
    </FormSectionCard>
  );
};

export default EventSection;
