import { useEffect, useState, useRef } from "react";
import FileList from "./fileList";
import { Text } from "@mantine/core";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";

const DNOrderFileList = ({ form, title, columnSpan = 4, disabled }) => {
  const { t } = useTranslation();

  const o = _.get(form.values, "order");
  const orderId = o?._id;
  const NO_ORDER_MSG = "No Order Assigned yet.";

  return (
    <>
      {/* {order && <Text>Order: {order?._id}</Text>} */}
      {/* DNOrderFileList */}
      {orderId && (
        <FileList
          relatedObject={{
            entity: "order",
            id: orderId,
            fieldName: "files",
          }}
          title={title}
          columnSpan={columnSpan}
          disabled={disabled}
        />
      )}
      {!orderId && <Text size="xs">{t(NO_ORDER_MSG)}</Text>}
    </>
  );
};

export default DNOrderFileList;
