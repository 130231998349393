import React, { useState, useEffect } from "react";
import {
  Text,
  Group,
  ActionIcon,
  UnstyledButton,
  Select,
  Textarea,
  Input,
  Autocomplete,
} from "@mantine/core";
import DataTable from "./dataTable";
import { useTranslation } from "react-i18next";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import DebitNoteAddEndorsementButton from "./debitNoteAddEndorsementButton";
import ActionCellRender from "./actionCellRender";
import { IconRefresh } from "@tabler/icons";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import { useCellRender } from "../hooks/useCellRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import FileList from "./fileList";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons";
import {
  DATA_ENDORSEMENT_ACTION,
  DATA_ENDORSEMENT_TYPE,
} from "./debitNoteAddEndorsementButton";
import DummyWidget from "./dummyWidget";

const DebitNoteEndorsementList = ({ form: mainForm, name }) => {
  const dnId = mainForm?.values?._id;
  const [rows, setRows] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [api] = useServerApi();
  const { t } = useTranslation();
  const auth = useAuthUser();
  const [cellRender] = useCellRender();
  const userRole = auth().userRole;

  const debitNoteLink =
    userRole.entityLinks.find((l) => l.entity === "debitNote")?.link ??
    "/m/gi/debitNote";

  const creditNoteLink =
    userRole.entityLinks.find((l) => l.entity === "creditNote")?.link ??
    "/m/gi/creditNote";

  const fetchEndorsements = async () => {
    try {
      if (!dnId) return;
      const data = await api.DebitNote.getEndorsements({
        id: dnId,
      });
      if (!data || !data.endorsements) return;
      // console.log(data);
      setRows(data.endorsements);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEndorsements();
  }, [dnId]);

  const onAdded = () => {
    fetchEndorsements();
  };

  const deleteEndorsement = async (data) => {
    if (!window.confirm("Are you sure you want to delete this endorsement?"))
      return;

    const result = await api.DebitNote.deleteEndorsement({
      id: data._id,
    });
    // console.log("result", result);
    fetchEndorsements();
  };

  const updateEndorsement = async (values) => {
    console.log("updateEndorsement", values);
    try {
      if (!values._id) return;
      await api.updateById({
        apiEntity: "endorsement",
        id: values._id,
        values,
      });
      fetchEndorsements();
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionBtnClick = ({ action, data, rowIndex }) => {
    // console.log("handleActionBtnClick", action, data, rowIndex);

    switch (action) {
      case "edit":
        // console.log("edit", data, rowIndex);
        formAction.open({ mode: "edit", id: data._id });
        break;
      case "delete":
        return deleteEndorsement(data);
    }
  };

  const tableSchema = {
    hidePagination: true,
    hideSearchBar: true,
    columns: [
      {
        field: "date",
        headerName: "Date",
        cellRender: (_, data) => (
          <>
            <div>
              <Text size="xs" c="dimmed">
                {data.code}
              </Text>
            </div>
            <div>{data.date ? moment(data.date).format("YYYY-MM-DD") : ""}</div>
          </>
        ),
      },

      { field: "type", headerName: "Endorsement Type" },
      {
        field: "action",
        headerName: "Action",
        cellRender: (_, data) => cellRender.EndorsementAction(data),
      },

      {
        field: "document",
        headerName: "Document",
        cellRender: (_, data) => (
          <>
            {data.endorsedDebitNote ? (
              <a
                href={`${debitNoteLink}?id=${data.endorsedDebitNote._id}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {cellRender.DebitNoteCode(data.endorsedDebitNote, false)}
              </a>
            ) : (
              ""
            )}
            {data.endorsedCreditNote ? (
              <a
                href={`${creditNoteLink}?id=${data.endorsedCreditNote._id}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {cellRender.CreditNote.code(data.endorsedCreditNote, false)}
              </a>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [],
        actionMenu: [
          {
            name: "edit",
            label: "Edit",
            props: {
              size: "xs",
            },
          },
          {
            name: "delete",
            label: "Delete",
            props: {
              color: "red",
              size: "xs",
            },
          },
        ],
      },
    ],
  };

  const formSchema = {
    name: "EditEndorsementSchema",
    title: "Edit Endorsement",
    description: "Please fill in the form to edit the Endorsement",
    closeAfterSave: true,

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
      position: "right",
    },

    apiEntity: "endorsement",
    initialValues: {},

    validate: {
      action: (value) =>
        !value ? "Please select the endorsement action" : null,
      type: (value) => (!value ? "Please select the endorsement type" : null),
      error: (value) => {
        // console.log("error", value);
        return value;
      },
    },
    showSaveBar: true,

    layout: {
      containers: [
        {
          key: "tabs",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Basic Info",
          },
          tab: [
            {
              key: "basicInfo",
              label: "Basic Info",
              value: "basicInfo",
            },
            {
              key: "files",
              label: "Files",
              value: "files",
            },
          ],
        },
        {
          key: "basicInfo",
          parent: "tabs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,

          props: {},
        },
        {
          key: "files",
          parent: "tabs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,

          props: {},
        },
      ],

      fields: [
        {
          name: "type",
          component: DummyWidget,
          parent: "basicInfo",
          props: {
            dummyRender: (v) => (
              <Input.Wrapper label="Endorsement Type">
                <Text size="md" c="dimmed">
                  {v.type}
                </Text>
              </Input.Wrapper>
            ),
          },
        },
        {
          name: "action",
          component: Select,
          parent: "basicInfo",
          props: {
            label: "Action",
            required: true,

            data: DATA_ENDORSEMENT_ACTION,
            clearable: true,
          },
        },
        {
          name: "date",
          parent: "basicInfo",
          component: DatePicker,
          type: "datePicker",

          props: {
            label: "Endorse Date",
            required: true,
            clearable: true,
            valueFormat: "YYYY-MM-DD",
            allowFreeInput: true,
          },
        },

        {
          name: "remark",
          parent: "basicInfo",
          component: Textarea,
          props: {
            label: "Remark",
          },
        },

        {
          name: "files",
          parent: "files",
          component: FileList,
          props: {},
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      console.log("DebitNoteAddEndorsementButton  > onSubmit", values);
      await updateEndorsement(values);

      formAction.close();
      showNotification({
        title: `Service added`,
        message: "Service added succesfully",
      });
    } catch (error) {
      console.log(error);
      showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: `Service add error`,
        message: error.msg ?? error.message ?? error,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  return (
    <>
      <Group position="right" mb="xs">
        <UnstyledButton onClick={() => fetchEndorsements()}>
          <Group spacing={2}>
            <ActionIcon size="sm">
              <IconRefresh size={16} />
            </ActionIcon>
            <Text size={"xs"}>{t("Refresh")}</Text>
          </Group>
        </UnstyledButton>
      </Group>
      {_.isEmpty(rows) && (
        <Group mb="xl">
          <Text size="xs">{t("No endorsements records found")}</Text>
        </Group>
      )}
      {!_.isEmpty(rows) && (
        <DataTable
          data={rows ?? []}
          columns={tableSchema.columns}
          hidePagination={true}
          hideSearchBar={true}
          // onRowDoubleClick={handleOnEdit}
          onActionBtnClick={handleActionBtnClick}
        />
      )}

      {!printing && (
        <DebitNoteAddEndorsementButton mainForm={mainForm} onAdded={onAdded} />
      )}
      {renderForm()}
    </>
  );
};

export default DebitNoteEndorsementList;
