import React, { useState, useEffect } from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextInput,
  Switch,
  Select,
  Textarea,
  ActionIcon,
  NumberInput,
  UnstyledButton,
  Group,
  Text,
} from "@mantine/core";
import {
  IconCircle,
  IconCirclePlus,
  IconExclamationMark,
  IconSettings,
} from "@tabler/icons";

import { DATA_BANK_WITH_CODE } from "../data/options";

import { DatePicker } from "@mantine/dates";
import FileList from "./fileList";

import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import RemoteSelect from "../components/remoteSelect";
import RemoteSelect2 from "../components/remoteSelect2";
import { DATA_ORDER_TYPE, DATA_COUNTRY } from "../data/options";
import Chips from "./chips";

import FormConfirmation from "./formConfirmation";
import RemarkList from "./remarkList";
import ReactJson from "react-json-view";
import { useFormatter } from "../hooks/useFomatter";
import DebitNotePaymentMatchingList from "./debitNotePaymentMatchingList";
import { useAuthUser } from "react-auth-kit";

const AddClientPaymentButton = ({ mainForm, onAdded }) => {
  const [api] = useServerApi();
  const apiEntity = "clientPayment";
  const [formatter] = useFormatter();
  const initialValues = {};
  const auth = useAuthUser();
  const clientId = mainForm?.values?.client?._id;
  const formSchema = {
    name: "AddClientPaymentSchema",
    title: "New Client Payment",
    description: "Please follow the step to create a New Client Payment",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
    },

    apiEntity,
    initialValues,

    validate: {
      amount: (value) => {
        if (!value) return "Payment Amount is required";
        if (value <= 0) return "Payment Amount must be greater than 0";
        return null;
      },
      bankAccount: (value) => {
        if (!value) return "Bank Account is required";
        return null;
      },
      date: (value) => {
        if (!value) return "Payment Date is required";
        return null;
      },
      files: (value) => {
        if (!value) return "Receipt File is required";
        return null;
      },
    },
    showSaveBar: true,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Payment Info",
          },
          tab: [
            {
              key: "t1",
              label: "Payment Info",
              //icon: IconSettings,
            },
            {
              key: "tab-multiple",
              label: "Multiple Debit Notes",
              // icon: IconSettings,
            },
          ],
        },
      ],

      fields: [
        //Payment Date
        {
          name: "date",
          component: DatePicker,
          parent: "t1",
          props: {
            label: "Payment Date",
            required: true,
            clearable: true,
            valueFormat: "YYYY-MM-DD",
            allowFreeInput: true,
          },
        },

        //Payment Amount
        {
          name: "amount",
          component: NumberInput,
          parent: "t1",
          props: {
            label: "Payment Amount",
            //parse as money
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
            precision: 2,
          },
        },

        //Direct to Provider
        {
          name: "directToProvider",
          parent: "t1",
          component: Switch,
          props: {
            type: "checkbox",
            size: "md",
            label: "Direct to Provider ",
            mt: "md",
          },
        },

        //Bank Account
        {
          name: "bankAccount",
          component: RemoteSelect2,
          parent: "t1",
          visibleOnly: (values, accessRight) => !values.directToProvider,
          props: {
            label: "Bank Account",
            apiEntity: "bankAccount",
            required: true,
            placeholder: "Bank Account",
            valueField: "_id",
            labelField: "name",

            // allowClear: true,
            // clearable: true,
            labelRender: (data) => {
              //Get bank name from bankAccount.bank
              return ` ${data.shortName ?? ""} : ${data.accountNumber ?? ""} `;
            },
            multiple: false,
            searchFields: ["name", "code", "cname"],
            clearable: true,
            sort: { by: "bank", order: "asc" },
          },
        },

        //Receipt File
        {
          name: "files",
          component: FileList,
          parent: "t1",
          props: {
            title: "Receipt File",
            tableMarginBottom: 10,
            showNoFilesMessage: false,
            defaultDisplayMode: 1,
          },
        },
        {
          name: "remark",
          parent: "t1",
          component: Textarea,
          props: {
            label: "Remark",
          },
        },
        // {
        //   name: "isMultiple",
        //   parent: "section-debit-note",
        //   component: Switch,
        //   props: {
        //     label: "Pay to multiple debit notes",
        //   },
        // },
        {
          name: "debitNotes",
          parent: "tab-multiple",
          component: DebitNotePaymentMatchingList,
          props: {
            label: "Debit Notes",
            clientId,
          },
        },
      ],
    },
  };

  formSchema.title = "Add Payment";

  const onSubmit = async ({ values, formMode }) => {
    try {
      console.log("Add New Payment Values", values);
      if (!values.date) {
        window.alert("Payment Date is required");
        return;
      }
      if (!values.bankAccount) {
        window.alert("Bank Account is required");
        return;
      }
      const totalAssignedAmount = +_.sumBy(
        values.debitNoteAssignments,
        "paymentAmount"
      ).toFixed(2);

      if (values.amount <= 0) {
        window.alert("Payment Amount must be greater than 0");
        return;
      }
      if (values.amount !== totalAssignedAmount) {
        window.alert(
          `Payment Amount (${values.amount}) must be equal to the total assigned amount (${totalAssignedAmount})`
        );
        return;
      }

      const data = await api.ClientPayment.add({
        payment: values,
        debitNote: mainForm?.values._id,
      });

      console.log("onSubmit data", data);

      formAction.close();
      if (onAdded) onAdded(data);

      api.addActivityLog({
        entityId: mainForm?.values._id,
        title: "Payment Added",
        by: auth().id,
      });

      mainForm.reload();
      // console.log("data", data);
      showNotification({
        title: `Payment Added`,
        message: "payment added succesfully",
      });
    } catch (error) {
      showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: `Service add error`,
        message: error.msg ?? error.message,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    if (!mainForm || !mainForm.values) return;

    if (
      // mainForm.values.paymentStatus === "PAID" ||
      mainForm.values.paymentStatus === "SETTLED"
    ) {
      showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: "Error",
        message: `Payment for ${mainForm.values.code} is already ${mainForm.values.paymentStatus}, cannot add new payment`,
      });
      return;
    }

    const id = mainForm?.values._id;
    if (!id) return;

    const v = {
      date: null,
      amount: 0,
      bankAccount: null,
      remark: "",
      files: [],
      isMultiple: false,
      debitNoteAssignments: [
        {
          debitNote: mainForm.values,
          paymentAmount: mainForm.values.clientPayable,
        },
      ],
    };

    formAction.open({ mode: "edit", id, data: v });
  };

  const { t } = useTranslation();
  return (
    <>
      <UnstyledButton onClick={() => handleActionClick()}>
        <Group spacing={"xs"}>
          <ActionIcon>
            <IconCirclePlus size={"1rem"} />
          </ActionIcon>
          <Text size={"xs"}>{t("Add Payment")}</Text>
        </Group>
      </UnstyledButton>

      {renderForm()}
    </>
  );
};

export default AddClientPaymentButton;
