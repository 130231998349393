import { Button, Group } from "@mantine/core";
import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import OrderConfirmPolicyButton from "./orderConfirmPolicyButton";
import OrderEditPolicyButton from "./orderEditPolicyButton";

import OrderReceiveActionButton from "./orderReceiveActionButton";
import OrderAddRiderActionButton from "./orderAddRiderActionButton";
import OrderAddServiceButton from "./orderAddServiceButton";

import OrderPrintSubmissionCoverSheetActionButton from "./orderPrintSubmissionCoverSheetActionButton";
import OrderPrintReceiveCoverSheetActionButton from "./orderPrintReceiveCoverSheetActionButton";

import OrderTerminateActionButton from "./orderTerminateActionButton";
import OrderEditButton from "./orderEditButton";
import OrderAdjustButton from "./orderAdjustButton";
import OrderReinstantiateButton from "./orderReinstantiateButton";

import NewTicketButton from "./newTicketButton";
import OrderDeliveryPolicyButton from "./orderDeliverPolicyButton";
import OrderCancelButton from "./orderCancelButton";
import OrderRedoCancelButton from "./orderRedoCancelButton";
import { useAccessRight } from "../hooks/useAccessRight";

const ORDER_STATUS_ALLOWED_TO_EDIT = [
  "DFT",
  "CAN",
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "UNKNOWN",
];

const ORDER_STATUS_ALLOW_CONSULTANT_TO_EDIT = ["DFT", "CAN"];
const ORDER_STATUS_ALLOWED_TO_EDIT_POLICY = [
  "INF",
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "ACT",
];

const ORDER_STATUS_ALLOWED_TO_RECEIVE = ["DFT", "RCV"];
const ORDER_STATUS_ALLOWED_TO_ADJUST = ["INF"];
const ORDER_STATUS_ALLOWED_TO_CONFIRM = [
  "RCV",
  "SUB",
  "PND",
  "AIP",
  "ACT",
  "UNKNOWN",
];

const ORDER_STATUS_ALLOWED_TO_REINSTANT = [
  "TER",
  "WTH",
  "COL",
  "SUR",
  "REJ",
  "LAP",
];

const OrderActionList = ({ form, role }) => {
  // let status = _.get(form.values, "status");

  const [getAccessRight, accessRight] = useAccessRight();
  const { status, code, consultants, _id, policyNumber } = form.values;
  // console.log("OrderActionList", consultants);
  const allowToEdit = () => ORDER_STATUS_ALLOWED_TO_EDIT.includes(status);
  const allowToEditByConsultant = () =>
    ORDER_STATUS_ALLOW_CONSULTANT_TO_EDIT.includes(status);
  const allowToEditPolicy = () =>
    ORDER_STATUS_ALLOWED_TO_EDIT_POLICY.includes(status) &&
    !_.isEmpty(policyNumber);
  const allowToAdjust = () => ORDER_STATUS_ALLOWED_TO_ADJUST.includes(status);
  const allowToConfirm = () => ORDER_STATUS_ALLOWED_TO_CONFIRM.includes(status);
  const allowToReceive = () => ORDER_STATUS_ALLOWED_TO_RECEIVE.includes(status);
  const allowToReinstantiate = () =>
    ORDER_STATUS_ALLOWED_TO_REINSTANT.includes(status);

  const allowToDeliver = () => status === "INF";
  const allowToCancel = () => status === "DFT";
  const allowToRedoCancel = () => status === "CAN";

  const showButton = accessRight === 15;
  const showReceiveButton = role === "OPERATION" || role === "ACCOUNTING";
  const showReceiveCoverSheet =
    role === "MANAGEMENT" ||
    role === "OPERATION" ||
    role === "ACCOUNTING" ||
    role === "CONSULTANT";

  const receiveCoverSheetLabel =
    role === "CONSULTANT" || role === "MANAGEMENT"
      ? "Print Cover Sheet"
      : "Receive Order";
  const receiveAction =
    role === "CONSULTANT" || role === "MANAGEMENT" ? "print" : "receive";
  const showAdjustOrderButton =
    role === "OPERATION" || role === "MANAGEMENT" || role === "ACCOUNTING";
  const showSubmitCoverSheet =
    role === "OPERATION" || role === "MANAGEMENT" || role === "ACCOUNTING";
  const showConfirmPolicy =
    role === "OPERATION" || role === "MANAGEMENT" || role === "ACCOUNTING";
  const showEditOrderButton =
    role == "OPERATION" || role === "MANAGEMENT" || role === "ACCOUNTING";
  const showTerminateOrderButton =
    role == "OPERATION" || role === "MANAGEMENT" || role === "ACCOUNTING";
  const showDeliverPolicy =
    role == "OPERATION" || role === "MANAGEMENT" || role === "ACCOUNTING";
  const showAddRiderButton =
    role == "CONSULTANT" ||
    role == "OPERATION" ||
    role === "MANAGEMENT" ||
    role === "ACCOUNTING";

  const showEditButtonByConsultant =
    role == "CONSULTANT" ||
    role === "ACCOUNTING" ||
    role == "OPERATION" ||
    role === "MANAGEMENT";

  const showReinstaniateOrderButton =
    role === "MANAGEMENT" ||
    role === "OPERATION" ||
    role === "ACCOUNTING" ||
    role == "OPERATION" ||
    role === "MANAGEMENT";

  const showCancelOrderButton =
    role === "CONSULTANT" ||
    role == "OPERATION" ||
    role === "MANAGEMENT" ||
    role === "ACCOUNTING";
  const showRedoCancelOrderButton =
    role === "CONSULTANT" ||
    role == "OPERATION" ||
    role === "MANAGEMENT" ||
    role === "ACCOUNTING";

  const { t } = useTranslation();

  return (
    <>
      {/* Role: {role} */}
      <Group mt={10}>
        {showAddRiderButton && (
          <OrderAddRiderActionButton
            mainForm={form}
            label={t("Add New Rider")}
          />
        )}
        {/* <OrderAddServiceButton mainForm={form} /> */}
        {/* {allowToReceive() && showReceiveButton && (
          <>
            <OrderReceiveActionButton
              mainForm={form}
              label={t("Receive Order 2")}
            />
          </>
        )} */}
        {role !== "CONSULTANT" && allowToEdit() && showEditOrderButton && (
          <OrderEditButton mainForm={form} label={t("Edit Order Details")} />
        )}

        {role == "CONSULTANT" &&
          showEditButtonByConsultant &&
          allowToEditByConsultant() && (
            <OrderEditButton mainForm={form} label={t("Edit Order Details")} />
          )}

        {allowToAdjust() && showAdjustOrderButton && (
          <OrderAdjustButton mainForm={form} label={t("Adjust Order")} />
        )}

        {allowToReceive() && showReceiveCoverSheet && (
          <OrderPrintReceiveCoverSheetActionButton
            mainForm={form}
            label={receiveCoverSheetLabel}
            action={receiveAction}
          />
        )}

        {showSubmitCoverSheet && (
          <OrderPrintSubmissionCoverSheetActionButton
            mainForm={form}
            label={t("Print Submit Cover Sheet")}
          />
        )}

        {allowToEditPolicy() && showConfirmPolicy && (
          <OrderEditPolicyButton mainForm={form} label={t("Edit Policy")} />
        )}

        {allowToConfirm() && showConfirmPolicy && (
          <OrderConfirmPolicyButton
            mainForm={form}
            label={t("Confirm Policy")}
          />
        )}

        {allowToDeliver() && showDeliverPolicy && (
          <OrderDeliveryPolicyButton
            mainForm={form}
            label={t("Deliver Policy")}
          />
        )}

        <NewTicketButton
          buttonType="fullWidth"
          code={code}
          entityId={_id}
          entity="order"
          ticketType={"TK-ORDER"}
          members={consultants?.map((c) => c._id)}
          label={t("Create Ticket")}
        />

        {showTerminateOrderButton && (
          <OrderTerminateActionButton
            mainForm={form}
            label={t("Terminate Policy")}
          />
        )}

        {showCancelOrderButton && allowToCancel() && (
          <OrderCancelButton mainForm={form} label={t("Cancel Order")} />
        )}

        {showRedoCancelOrderButton && allowToRedoCancel() && (
          <OrderRedoCancelButton
            mainForm={form}
            label={t("Redo Cancel Order")}
          />
        )}
        {showReinstaniateOrderButton && allowToReinstantiate() && (
          <OrderReinstantiateButton
            mainForm={form}
            label={t("Reinstantiate Order")}
          />
        )}
      </Group>
    </>
  );
};

export default OrderActionList;
