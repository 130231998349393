import React, { useState, useEffect } from "react";
import ActionCellRender from "../../components/actionCellRender";
import { IconTrash, IconPencil, IconSettings, IconLink } from "@tabler/icons";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { useCellRender } from "../../hooks/useCellRender";
import { useServerApi } from "../../hooks/userServerApi";
import { Group, UnstyledButton } from "@mantine/core";
import { PeriodFilter } from "../../components/periodFilter";
import _ from "lodash";
import ReactJson from "react-json-view";
import { useAuthUser } from "react-auth-kit";
import FormSection from "../../components/formSection";
import { Page } from "../../layout/page";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/dataTable";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
const apiEntity = "order";

const initialValues = {
  type: "NEW",
  name: "",
  payment: {},
  termination: {},
  signedPlace: "Hong Kong",
  consultants: [],
  signedDate: "", //moment().format("YYYY-MM-DD"),
};

export default function OrderRenewal({ role = "CONSULTANT", numQuarters = 4 }) {
  const [cellRender] = useCellRender();
  const [api] = useServerApi();
  const auth = useAuthUser();
  const [preQuery, setPreQuery] = useState(null);
  const [period, setPeriod] = useState({
    startDate: moment()
      .startOf("month")
      .subtract(1, "year")
      .format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const navigate = useNavigate();
  const consultantId = auth().id;

  const [orders, setOrders] = useState([]);

  const createQuery = () => {
    const q = [{ "payment.payMode": "ANNUALLY" }, { status: "INF" }];

    if (period.startDate)
      q.push({
        $expr: {
          $gt: [
            { $month: "$signedDate" },
            moment(period.startDate).month() + 1,
          ],
        },
      });

    if (period.endDate)
      q.push({
        $expr: {
          $lte: [{ $month: "$signedDate" }, moment(period.endDate).month() + 1],
        },
      });

    q.push({
      $expr: {
        $lt: [{ $year: "$signedDate" }, moment(period.endDate).year()],
      },
    });
    if (role === "CONSULTANT") {
      q.push({ consultants: auth().id });
    }

    return { $and: q };
  };

  // const fetchRenewal = async () => {
  //   try {
  //     // if (_.isEmpty(period)) return;
  //     const result = await api.Order.getRenewal(period, consultantId);
  //     if (result.data) {
  //       return setOrders(result.data.orders);
  //     } else setOrders([]);
  //     console.log("result", result);
  //   } catch (error) {
  //     console.log(error);
  //     // toast.error(result.message);
  //   }
  // };

  const tableSchema = {
    showActionColumn: true,
    preQuery,
    showOnlyPreQueryReady: true,
    columns: [
      {
        field: "code",
        headerName: "Order",
        sortable: true,
        cellRender: (col, order) => (
          <UnstyledButton
            onClick={() =>
              window.open(
                `/consultant/order?id=${order._id}&mode=edit`,
                "_blank"
              )
            }
          >
            <Group>
              {cellRender.Order.code(order, true)}
              <IconLink size={15}> </IconLink>
            </Group>
          </UnstyledButton>
        ),
      },
      {
        field: "payment.payMode",
        headerName: "Renewal Date",
        sortable: true,
        cellRender: (col, order) => moment(order.signedDate).format("MMMM DD"),
      },
      {
        field: "client",
        sortable: true,
        cellRender: (col, data) => cellRender.ClientInfo(data.client, true),
      },
      {
        field: "payment.payMode",
        headerName: "Pay Mode",
        sortable: true,
        cellRender: (col, order) => order.payment.payMode,
      },
      {
        field: "payment.premium",
        headerName: "Preimum",
        sortable: true,
        cellRender: (col, order) =>
          order.payment.premium
            ? `${order.payment.currency}  ${Intl.NumberFormat().format(
                order.payment.premium
              )}`
            : "-",
      },

      {
        field: "product",
        headerName: "Product",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductInfo(data.product),
      },
      {
        field: "policyNumber",
        headerName: "Policy No.",

        // cellRender: (col, data) => cellRender.Users(data.consultants, true),
      },

      // {
      // 	field: "consultants",
      // 	headerName: "Consultants",
      // 	sortable: true,
      // 	cellRender: (col, data) => cellRender.Users(data.consultants, 3, true),
      // },

      // {   field: 'followup', sortable: true,   },
      // {
      // 	field: "status",
      // 	sortable: true,
      // 	cellRender: (col, data) => cellRender.Status(data.status),
      // },
    ],
    searchableFields: [
      "code",
      "status",
      "applicant.name",
      "applicant.cname",
      "applicant.id",
      "cname",
      "email",
      "phone",
      "policyNumber",
    ],
  };
  const formSchema = {};

  useDeepCompareEffect(() => {
    // fetchRenewal();
    const q = createQuery();
    setPreQuery(q);
    tableSchema.preQuery = q;
  }, [consultantId, period]);
  return (
    <>
      {/* <ReactJson src={period} style={{ background: "white" }}></ReactJson> */}

      {/* <ReactJson
        src={preQuery}
        style={{ background: "white" }}
        collapsed
      ></ReactJson> */}

      {/* <DataTable
        data={orders}
        columns={tableSchema.columns}
        hidePagination={true}
        hideSearchBar={true}
      /> */}
      {preQuery && (
        <PageList
          title={"Order Renewal"}
          apiEntity={"order"}
          tableSchema={tableSchema}
          // data={orders}
          // preQueryNotEmpty={true}
          // preQuery={preQuery}
          searchByTextField={false}
          formSchema={formSchema}
          initSort={{ by: "signedDate", order: "asc" }}
          hideSearchBar={true}
          tableHeader={{
            component: PeriodFilter,
            props: {
              title: "User Management",
              handlePeriodChange: setPeriod,
              forward: true,
              numPeriods: 13,
              by: "M",
              position: "left",
              pb: "xl",
            },
          }}
        />
      )}
    </>
  );
}
