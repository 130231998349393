import React, { useState } from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { Button } from "@mantine/core";
import { IconSettings } from "@tabler/icons";

import OrderSubmissionCheckList from "./orderSubmissionCheckList";
import OrderSubmissionConfirm from "./orderSubmissionConfirm";

import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import Order from "../pages/operation/order";
import { useTranslation } from "react-i18next";
const OrderPrintReceiveCoverSheetActionButton = ({
  mainForm,
  label = "Print Cover Sheet",
  action = "print",
}) => {
  const [api] = useServerApi();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const formSchema = {
    title: label,
    description: "Please follow the steps",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: false,
    closeAfterSave: true,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Receive Date",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1-0", label: "Receive Date", icon: IconSettings },

            { key: "t1-1", label: "Checklist", icon: IconSettings },
            { key: "t1-2", label: "Confirm", icon: IconSettings },
          ],
        },
        {
          key: "section-checklist",
          parent: "t1-1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Document CheckList",
            description:
              "Please make sure the below documents are ready before submit to provider",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },

        {
          key: "section-confirm",
          parent: "t1-2",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Confirm Submission",
            description: "Please confirm the order submission to provider",
            withBorder: false,
            mt: 0,
            ml: 20,
          },
        },
      ],

      fields: [
        {
          name: "receivedDate",
          component: DatePicker,
          parent: "t1-0",
          type: "datePicker",
          props: {
            required: true,
            label: "Recevie Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
            defaultValue: new Date(),
          },
        },
        {
          name: "checkList",
          component: OrderSubmissionCheckList,
          parent: "section-checklist",
          props: {
            internal: true,
          },
        },

        {
          name: "checkList",
          component: OrderSubmissionConfirm,
          parent: "section-confirm",
          props: { internal: true },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    // values.status = "RCV";

    // if (!_.isEmpty(values.riders)) {
    //   values.riders.forEach((r) => (r.status = "RCV"));
    // }
    // mainForm.setValues({ ...values });
    // await mainForm.saveValues(values);

    printCoverSheet(values);
  };

  const printCoverSheet = async (values) => {
    try {
      const list = values.product.checkList;
      setLoading(true);
      // console.log("Print Cover Sheet", list);
      const checkList = {
        internal: list
          .filter((item) => item.frontier)
          .map((item) => ({
            name: item.name,
            checked: item.checked,
            optional: item.optional,
            _id: item._id,
          })),
      };

      formAction.close();

      // console.log("Received Date", values.receivedDate);
      let result = await api.Order.printSubmissionCoverSheet(
        values._id,
        checkList,
        "internal",
        values.receivedDate,
        action === "receive"
      );
      // console.log("Print Cover Sheet", result);

      setLoading(false);

      if (result && result.url) {
        window.open(result.url, "_blank");
        formAction.close();

        mainForm.setFieldValue("status", result.order.status);
        mainForm.setFieldValue("receivedDate", result.order.receivedDate);
        mainForm.setFieldValue("riders", result.order.riders);
        mainForm.setFieldValue(
          "receiveCheckList",
          result.order.receiveCheckList
        );
        showNotification({
          title: `${values?.code} Received Successfully`,
          message: `Order received.`,
        });
      }
      mainForm.reload();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      <Button fullWidth onClick={handleActionClick} loading={loading}>
        {loading ? t("Printing..") : label}
      </Button>

      {renderForm()}
    </>
  );
};

export default OrderPrintReceiveCoverSheetActionButton;
