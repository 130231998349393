import React, { useCallBack, useState } from "react";
import axios from "axios";
import { useAuthHeader, useSignOut } from "react-auth-kit";
import { authName } from "../App";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons";
import _ from "lodash";

export const useServerApi = () => {
  const token = localStorage.getItem(authName);
  const navigate = useNavigate();

  const getHeader = (method = "GET", body) => {
    switch (method) {
      case "GET":
        return {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
          },
        };

      case "POST": {
        return {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(body),
        };
      }

      case "DELETE": {
        return {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
          },
        };
      }

      case "PUT": {
        return {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(body),
        };
      }
    }
  };

  const handleResponseError = (result) => {
    if (result.success) return;
    // console.log("handleResponseError", result.message);
    if (result.message && result.message === "ERR_INVALID_TOKEN") {
      navigate("/login");
    }
  };

  const aggregate = async ({ apiEntity, aggregations }) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/aggregate`;

    try {
      let result = await fetch(url, getHeader("POST", { aggregations }));
      result = await result.json();
      if (!result || !result.success) return handleResponseError(result);

      return result.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const search = async ({
    apiEntity,
    pageSize = 50,
    currentPage = 1,
    sort,
    searchQuery,
    searchText,
    searchByTextField,
    preQuery,
    select,
    byAggregation = true,
  }) => {
    try {
      let url = `${
        process.env.REACT_APP_SERVER_URL
      }/${apiEntity}/search?limit=${pageSize}&page=${currentPage - 1}`;

      if (sort) {
        url += sort.by ? `&sort=${sort.by}` : "";
        url += sort.by ? `&order=${sort.order}` : "";
      }

      if (select) {
        url += `&select=${select}`;
      }

      let result = await fetch(
        url,
        getHeader("POST", {
          condition: searchQuery,
          byAggregation,
          searchByTextField,
          searchText,
          preQuery,
        })
      );

      result = await result.json();
      // console.log(result);
      if (!result || !result.success) return handleResponseError(result);
      return result.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const add = async ({ apiEntity, values }) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}`,
        getHeader("POST", values)
      );
      result = await result.json();

      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const removeById = async ({ apiEntity, id }) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/${id}`,
        getHeader("DELETE")
      );
      result = await result.json();
      if (!result.success && result.error) throw result.error;
      return result;
    } catch (error) {
      console.log(error);
      showNotification({
        title: `Delete fail`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: _.isString(error) ? error : error?.message ?? error?.msg,
      });
    }
  };

  const getDistinctValues = async ({ apiEntity, field, populate }) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/getDistinct`,
        getHeader("POST", {
          field,
          populate,
        })
      );
      result = await result.json();
      // console.log("getDistinctValues", result);

      if (!result.success) return handleResponseError(result);
      return result.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getById = async ({ apiEntity, id }) => {
    try {
      if (!id || !apiEntity) return null;
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/${id}`,
        getHeader("GET")
      );
      result = await result.json();
      if (!result || !result.success) return handleResponseError(result);

      return result.data;
    } catch (error) {
      console.log(error);
    }
  };

  const copyById = async ({ apiEntity, id, newCode = false, fields }) => {
    try {
      if (!id || !apiEntity) return null;
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/${id}`,
        getHeader("GET")
      );
      result = await result.json();
      if (!result || !result.success) return handleResponseError(result);

      let newData = {};

      //only copy the fields if fields is provided
      if (fields) {
        fields.forEach((field) => {
          newData[field] = result.data[field];
        });
      } else {
        newData = { ...result.data };
        delete newData._id;
        delete newData.createdAt;
        if (newCode) delete newData.code;
        if (newData.code) {
          newData.code += "(copy)";
        }
      }

      let result2 = await add({ apiEntity, values: newData });
      if (!result2 || !result2.success) return handleResponseError(result2);

      let data = await getById({ apiEntity, id: result2.data._id });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateById = async ({ apiEntity, values, id }) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/${id}`,
        getHeader("PUT", values)
      );
      result = await result.json();
      // console.log("Update", apiEntity, result, values);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const importBatch = async ({ apiEntity, file, importData }) => {
    try {
      let data = new FormData();
      data.append("file", file);
      data.append("filename", file.name); // use orginial name
      data.append("token", token);
      data.append("importData", JSON.stringify(importData));

      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/importBatch`,
        {
          method: "POST",
          body: data,
          redirect: "follow",
        }
      );
      result = await result.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const getErrorMsg = (error) => {
    try {
      if (typeof error != "object") return error;
      let msg = Object.entries(error)
        .map(([key, value]) => value.message)
        .join(" ,");
      // console.log(msg);
      return msg;
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFile = async (uploadFolder, file, entityId, exisitingFiles) => {
    try {
      let data = new FormData();

      data.append("file", file);
      data.append("filename", file.name);
      data.append("exisitingFiles", JSON.stringify(exisitingFiles));
      data.append("entityId", entityId);
      data.append("uploadFolder", uploadFolder);
      data.append("token", token);
      // console.log(
      //     "uploadFile",
      //     `${process.env.REACT_APP_SERVER_URL}/file/upload/${uploadFolder}`
      // );
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/file/upload`,
        {
          method: "POST",
          body: data,
          redirect: "follow",
        }
      );

      result = await result.json();
      if (!result || !result.success) return handleResponseError(result);
      console.log("uploadFile success", result);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const renameFile = async (id, link, name) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/file/rename`,
        getHeader("POST", { id, link, name })
      );
      result = await result.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const downloadZipFiles = async (filename, links) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/file/zip`,
        getHeader("POST", { filename, files: links })
      );

      result = await result.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const getOverridingResult = async ({
    preimum,
    rate,
    plan,
    providerExchangeRate,
    internalExchangeRate,
    skipProviderRate = false,
  }) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/commissionOverridingPlan/test`,
        getHeader("POST", {
          preimum,
          rate,
          plan,
          providerExchangeRate,
          internalExchangeRate,
          skipProviderRate,
        })
      );
      result = await result.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  const DataFeed = {
    import: async (file) => {
      try {
        let data = new FormData();
        data.append("file", file);
        data.append("filename", file.name); // use orginial name
        data.append("token", token);
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/dataFeed/import`,
          {
            method: "POST",
            body: data,
            redirect: "follow",
          }
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    downloadIssues: async (id) => {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/dataFeed/downloadIssues/${id}`,
        getHeader("GET")
      );
      result = await result.json();
      return result;
    },
  };
  const OrderProfolio = {
    import: async (file) => {
      try {
        let data = new FormData();
        data.append("file", file);
        data.append("filename", file.name); // use orginial name
        data.append("token", token);
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/profolioImport/import`,
          {
            method: "POST",
            body: data,
            redirect: "follow",
          }
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    getValuations: async (policyNumber) => {
      try {
        let result = await search({
          apiEntity: "policyProfolio",
          pageSize: 100,
          searchQuery: {
            policyNumber,
          },
          select: "valuationDate _id policyNumbner",
        });
        // console.log("getValuations", result);
        result = await result.json();
        return result.docs;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const PayrollPayment = {
    print: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payrollPayment/print/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    printPdf: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payrollPayment/printPdf/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Wallet = {
    getOpeningBalance: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/wallet/getOpeningBalance/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    setActive: async (id, isActive) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/wallet/setActive/${id}`,
          getHeader("POST", {
            isActive,
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getReport: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/wallet/getReport`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const TopRanking = {
    getStats: async (period, showOnHomePage) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/topRanking/getStats`,
          getHeader("POST", {
            period,
            showOnHomePage,
          })
        );

        result = await result.json();
        // console.log("top ranking get stats", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const CalendarEvent = {
    getByMonth: async (d) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/calendarEvent/getByMonth`,
          getHeader("POST", {
            date: d,
          })
        );
        result = await result.json();
        // console.log("Calendar events", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const User = {
    signIn: async (email, password) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/authenticate`,
          getHeader("POST", {
            email,
            password,
          })
        );
        console.log(result);
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    resetPassword: async (email) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/resetPassword`,
          getHeader("POST", {
            email,
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getListGroupByRole: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getListGroupByRole/`,
          getHeader("GET")
        );
        result = await result.json();
        // console.log("API User getListGroupByRole ",result)
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getAllFunctions: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/userRole/allFunction`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data.functions ?? [];
      } catch (error) {
        console.log(error);
      }
    },

    getPortalLogin: async (userId, providerId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getPortalLogin/${userId}/${providerId}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    transferClients: async ({
      userId,
      consultantId,
      clientIds,
      transferBidOwnership,
    }) => {
      try {
        // console.log("api Transfer Clients", {
        //   userId,
        //   consultantId,
        //   clientIds,
        //   transferBidOwnership,
        // });
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/transferClients`,
          getHeader("POST", {
            userId,
            consultantId,
            clientIds,
            transferBidOwnership,
          })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    getNonReleaseCommissionBidsCount: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getNonReleaseCommissionBidsCount/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    getReport: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getReport`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getNameCardReport: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getNameCardReport`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getNonSettledBidsCount: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getNonSettledBidsCount/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    getProducerRate: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getProducerRate/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        throw error;
      }
    },

    getClients: async (id, soleAgent = "true") => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getClients/${id}/${soleAgent}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    terminate: async (termination) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/terminate`,
          getHeader("POST", { termination })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    changePassword: async ({ id, oldPassword, newPassword, newPassword2 }) => {
      try {
        // console.log(id, oldPassword, newPassword, newPassword2);

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/changePassword`,
          getHeader("POST", {
            id,
            oldPassword,
            newPassword,
            newPassword2,
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        throw error;
      }
    },

    checkLicence: async (id, productTypeId) => {
      try {
        if (!id || !productTypeId) return;

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/checkLicence`,
          getHeader("POST", {
            id,
            productType: productTypeId,
          })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        throw error;
      }
    },

    setStatus: async (id, status) => {
      try {
        if (!status || !id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/setStatus/${id}`,
          getHeader("POST", {
            status,
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        throw error;
      }
    },

    getWallets: async (userId) => {
      try {
        if (!userId) return [];
        const result = await search({
          apiEntity: "wallet",
          searchQuery: {
            $or: [
              { user: userId },
              {
                "members.user": {
                  $in: [userId],
                },
              },
            ],
          },
        });

        // console.log("FetchWallet", result);
        return result.docs ?? [];
      } catch (error) {
        console.log(error);
      }
    },

    getForcastPayroll: async (userId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getForcastPayroll/${userId}`,
          getHeader("POST", {
            period: 1, //1year
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getOverridingPlan: async (userId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getOverridingPlan/${userId}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getTeamMembers: async (userId, numlevel = 2) => {
      try {
        if (!userId) return [];

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getTeamMembers/${userId}`,
          getHeader("POST", {
            numlevel, // Number of level
          })
        );

        result = await result.json();
        return result.data.teamMembers ?? [];
      } catch (error) {
        console.log(error);
      }
    },
    getOrderStatics: async (userId, period) => {
      try {
        if (!userId) return;

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getOrderStatics/${userId}`,
          getHeader("POST", {
            period, //
          })
        );

        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    disableCreateOrder: async (userId, disabled) => {
      try {
        console.log("disabled", disabled);
        if (!userId) return;

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/disableCreateOrder/${userId}`,
          getHeader("POST", {
            disabled, //
          })
        );

        result = await result.json();

        return result;
      } catch (error) {}
    },
  };

  const ConsultantIncentive = {
    getLatest: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/consultantIncentive/getLatest`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const ClientPromotion = {
    getLatest: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/clientPromotion/getLatest`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Bid = {
    getStatByWallet: async (walletId) => {
      // console.log("getStatByWallet", walletId);
      if (!walletId) return null;
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/bid/getStatByWallet/${walletId}`,
        getHeader("GET")
      );
      result = await result.json();
      return result;
    },
  };
  const Ticket = {
    getByUser: async (id) => {
      try {
        if (!id) return null;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/ticket/getByUser/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    create: async (values) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/ticket/create`,
          getHeader("POST", values)
        );
        result = await result.json();
        console.log(result);
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    addMessage: async (id, message) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/ticket/addMessage/${id}`,
          getHeader("POST", { message })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    updateTicketStatus: async (id, status) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/ticket/updateTicketStatus`,
          getHeader("POST", { status, id })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    updateTicketMembers: async (id, members) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/ticket/updateTicketMembers`,
          getHeader("POST", { members, id })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    // getMessages: async (id) => {
    //     if (!id) return null;
    //     let result = await fetch(
    //         `${process.env.REACT_APP_SERVER_URL}/ticket/getMessages/${id}`,
    //         getHeader("GET")
    //     );

    //     result = await result.json();
    //     return result;
    // },
  };

  const QuoRequest = {
    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/quoRequest/getStatSummary/`,
          getHeader("GET")
        );

        result = await result.json();

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatSummaryByConsultant: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/quoRequest/getStatSummaryByConsultant/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    copyToDN: async (values) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/quoRequest/copyToDN/${values._id}`,
          getHeader("POST", { values })
        );

        result = await result.json();
        console.log(result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };
  const Client = {
    getReport: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getReport`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getRiskStats: async (id) => {
      try {
        if (!id) return null;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getRiskStats/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getBirthdays: async (id, currentDate) => {
      try {
        if (!id) return null;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getBirthdays/${id}`,
          getHeader("POST", {
            date: currentDate,
          })
        );

        result = await result.json();
        // console.log("getBirthdays", result);
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getRiskRPQ: async (id) => {
      try {
        if (!id) return null;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getRiskRPQ/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    changeServiceRight: async ({ id, files, consultants }) => {
      // console.log("changeServiceRight ", id , consultants)
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/changeServiceRight`,
          getHeader("POST", {
            id,
            consultants,
            files,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getRiskAML: async (id) => {
      try {
        if (!id) return null;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getRiskAML/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getStatSummary/`,
          getHeader("GET")
        );

        result = await result.json();

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatSummaryByConsultant: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getStatSummaryByConsultant/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    updateRiskAML: async (id, aml) => {
      try {
        await updateById({ apiEntity: "client", values: { aml: aml }, id });
      } catch (error) {
        console.log(error);
      }
    },

    updateRiskRPQ: async (id, rpq) => {
      try {
        await updateById({ apiEntity: "client", values: { rpq: rpq }, id });
      } catch (error) {
        console.log(error);
      }
    },
  };
  const Renewal = {
    print: async (id, format = "pdf") => {
      //Quo : Quotation, RN: Renewal Notice
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/renewal/print/${id}/${format}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getReport: async (month) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/renewal/getReport/${month}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/renewal/getStatSummary/`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    printCancelNotice: async (id) => {
      //Quo : Quotation, RN: Renewal Notice
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/renewal/printCancelNotice/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    confirm: async (id) => {
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/renewal/confirm/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    copyToDN: async (values) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/renewal/copyToDN/${values._id}`,
          getHeader("POST", { values })
        );

        return await result.json();
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Quotation = {
    print: async (id, type = "QUO", format = "pdf") => {
      //Quo : Quotation, RN: Renewal Notice
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/quotation/print/${id}/${type}/${format}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    copyToDN: async (values) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/quotation/copyToDN/${values._id}`,
          getHeader("POST", { values })
        );

        result = await result.json();
        // console.log(result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/quotation/getStatSummary/`,
          getHeader("GET")
        );
        // console.log("API order getStatSummary ", result);
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    confirm: async (id) => {
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/quotation/confirm/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const CreditNote = {
    print: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/creditNote/print/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/creditNote/getStatSummary/`,
          getHeader("GET")
        );
        // console.log("API order getStatSummary ", result);
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    confirmSettlement: async ({ id, settlement }) => {
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/creditNote/confirmSettlement`,
          getHeader("POST", {
            settlement,
            id,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const DebitNote = {
    print: async (id, type = "pdf") => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/print/${id}/${type}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    printReceipt: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/printReceipt/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/getStatSummary/`,
          getHeader("GET")
        );
        // console.log("API order getStatSummary ", result);
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    confirmPayment: async ({ id, payment }) => {
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/confirmPayment`,
          getHeader("POST", {
            payment,
            id,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    unconfirmPayment: async ({ id }) => {
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/unconfirmPayment/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    holdCover: async ({ id, holdCover }) => {
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/holdCover`,
          getHeader("POST", {
            holdCover,
            id,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        throw error;
      }
    },
    confirmPolicy: async ({ id, dn }) => {
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/confirmPolicy`,
          getHeader("POST", {
            id,
            policyNumber: dn.policyNumber,
            confirmedAt: dn.confirmedAt,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    confirmSettlement: async ({ id, settlement }) => {
      try {
        if (!id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/confirmSettlement`,
          getHeader("POST", {
            settlement,
            id,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    unSettle: async (id) => {
      try {
        if (!id) return;
        console.log("unSettle", id);
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/unSettle/${id}`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getReport: async ({ fromDate, toDate }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/getReport`,
          getHeader("POST", {
            fromDate,
            toDate,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    refundExcess: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/refundExcess/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    addEndorsement: async ({ endorsement, dn }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/addEndorsement`,
          getHeader("POST", { endorsement, dn })
        );
        result = await result.json();
        if (!result || !result.success) throw result.error;

        // console.log("DebitNote addEndorsement", result);
        return result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    getEndorsements: async ({ id }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/getEndorsements/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    deleteEndorsement: async ({ id }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/debitNote/deleteEndorsement/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  };

  const Cpd = {
    getUserDetail: async ({ year, userId, type }) => {
      // console.log("getUserDetail", year, userId);
      try {
        if (!userId) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/cpd/getUserDetail`,
          getHeader("POST", {
            year,
            userId,
            type,
          })
        );

        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getIAYearReport: async (year) => {
      try {
        if (!year) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/cpd/getIAYearReport`,
          getHeader("POST", {
            year,
          })
        );
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getMPFYearReport: async (year) => {
      try {
        if (!year) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/cpd/getMPFYearReport`,
          getHeader("POST", {
            year,
          })
        );
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const OrderService = {
    printSubmitCoverSheet: async (orderService) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/orderService/printCoverSheet`,
          getHeader("POST", {
            service: orderService,
          })
        );

        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getReport: async ({ fromDate, toDate }) => {
      try {
        console.log("OrderService get Report");

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/orderService/getReport`,
          getHeader("POST", {
            fromDate,
            toDate,
          })
        );

        result = await result.json();
        console.log(result);
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Order = {
    getStatSummaryByConsultant: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getStatSummaryByConsultant/${id}`,
          getHeader("GET")
        );
        // console.log("getStatSummaryByConsultant", result);
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getReport: async ({ fromDate, toDate, dateType }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getReport`,
          getHeader("POST", {
            fromDate,
            toDate,
            dateType,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    getIAReportEncl2: async ({ fromDate, toDate }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getIAReportEncl2`,
          getHeader("POST", {
            fromDate,
            toDate,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getIAReportEncl10: async ({ fromDate, toDate }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getIAReportEncl10`,
          getHeader("POST", {
            fromDate,
            toDate,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getRenewal: async (period, consultantId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getRenewal`,
          getHeader("POST", { period, consultant: consultantId })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getStatSummary/`,
          getHeader("GET")
        );
        // console.log("API order getStatSummary ", result);
        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    addRider: async ({ riderValue, basicPlanOrderId }) => {
      // console.log("Userserver add rider", riderValue, basicPlanOrderId);
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/addRider`,
          getHeader("POST", {
            riderValue,
            basicPlanOrderId,
          })
        );
        result = await result.json();
        // console.log("API order addRider", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    addService: async ({ service }) => {
      // console.log("Userserver add rider", riderValue, basicPlanOrderId);
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/addService`,
          getHeader("POST", {
            service,
          })
        );
        result = await result.json();
        // console.log("API order addRider", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStats: async (period, userId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getStats`,
          getHeader("POST", {
            period,
            userId,
          })
        );

        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result?.data;
      } catch (error) {
        console.log(error);
      }
    },

    getQuotation: async (orderId) => {
      try {
        let result = api.search({
          apiEntity: "quotation",
          pageSize: 20,
          currentPage: 1,
          searchQuery: {
            order: orderId,
          },
        });

        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getInvoices: async (orderId) => {
      try {
        let result = api.search({
          apiEntity: "invoice",
          pageSize: 20,
          currentPage: 1,
          searchQuery: {
            order: orderId,
          },
        });

        result = await result.json();
        if (!result || !result.success) return handleResponseError(result);

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    receiveOrder: async (id, checkList, receivedDate) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/receiveOrder/${id}`,
          getHeader("POST", { checkList, receivedDate })
        );
        result = await result.json();
        // console.log("receiveOrder", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    printSubmissionCoverSheet: async (
      orderId,
      checkList,
      type,
      submissionDate,
      receive = false
    ) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/printSubmissionCoverSheet/${orderId}`,
          getHeader("POST", {
            checkList,
            type,
            submissionDate,
            receive,
          })
        );
        result = await result.json();

        return result.data;
      } catch (error) {
        // console.log(error);
      }
    },

    confirmPolicy: async (orderId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/confirmPolicy/${orderId}`,
          getHeader("GET")
        );
        result = await result.json();
        // console.log("confirm policy", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    generateCommissionPayment: async (orderId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/generateCommissionPayment/${orderId}`,
          getHeader("GET")
        );
        result = await result.json();
        console.log(result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getCommissionPayment: async (orderId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getCommissionPayment/${orderId}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    terminate: async (id, termination) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/terminate`,
          getHeader("POST", { id, termination })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const CommissionPayment = {
    getWaitingList: async (expectedPeriod) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionPayment/getWaitingList/${expectedPeriod}`,
          getHeader("GET")
        );
        result = await result.json();
        // console.log("getWaitingList", expectedPeriod, result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const CommissionStatement = {
    import: async (file) => {
      try {
        let data = new FormData();
        data.append("file", file);
        data.append("filename", file.name); // use orginial name
        data.append("token", token);
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/import`,
          {
            method: "POST",
            body: data,
            redirect: "follow",
          }
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getReport: async ({ period }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/getReport/${period}`,
          getHeader("GET")
        );
        result = await result.json();
        // console.log("get report", result);
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    downloadPendingCase: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/downloadPendingCase/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    verifyRow: async (row) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/verifyRow`,
          getHeader("POST", { row })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    verifyRowAmount: async (row) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/verifyRowAmount`,
          getHeader("POST", { row })
        );
        result = await result.json();
        // console.log(result);
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    resetRow: async (row) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/resetRow`,
          getHeader("POST", { row })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    rowAcceptPayment: async (row) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/rowAcceptPayment`,
          getHeader("POST", { row })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getMissingPayment: async (period, rows) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/getMissingPayment`,
          getHeader("POST", { period, rows })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    linkMorePayment: async (row) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/linkMorePayment`,
          getHeader("POST", { row })
        );
        result = await result.json();
        return result.data?.row;
      } catch (error) {
        console.log(error);
      }
    },

    confirmAllVerifiedRow: async (id) => {
      try {
        if (!id) return null;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commissionStatement/confirmAllVerifiedRow/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        // console.log(
        //     "%cuserServerApi.js line:422 result",
        //     "color: #007acc;",
        //     result
        // );
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const ClientPayment = {
    add: async ({ payment, debitNote }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/clientPayment/add`,
          getHeader("POST", { payment, debitNote })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    remove: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/clientPayment/remove/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    update: async (id, values) => {
      try {
        console.log("api update values", id, values);
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/clientPayment/update/${id}`,
          getHeader("POST", { values })
        );
        result = await result.json();
        console.log("api update result", result);
        if (!result.success) {
          throw result.message;
        }
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  };

  const GISettlement = {
    getPending: async (id, provider) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/giSettlement/getPending/${id}`,
          getHeader("POST", { provider })
        );
        result = await result.json();
        // console.log("getPending", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    confirm: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/giSettlement/confirm/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    print: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/giSettlement/print/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    removeDebitNote: async (debitNoteId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/giSettlement/removeDebitNote`,
          getHeader("POST", { debitNoteId })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const BankAccount = {
    getMatchingTransaction: async (id, type) => {
      try {
        console.log("getMatchingTransaction", id);
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/bankAccount/getMatchingTransaction/${id}`,
          getHeader("POST", { type })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    confirmMatching: async (matching, id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/bankAccount/confirmMatching/${id}`,
          getHeader("POST", { matching })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const GIMatchPayment = {
    getUnMatch: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/giMatchPayment/getUnMatch/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        // console.log("getPending", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    confirm: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/giMatchPayment/confirm/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Payroll = {
    getPendingPayroll: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/getPendingPayroll/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        // console.log("getPending", result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    export: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/export/${id}`,
          getHeader("GET")
        );
        // console.log(result);
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    //Report By Consultant
    exportDetail: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/exportDetail/${id}`,
          getHeader("GET")
        );
        // console.log(result);
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    //Report By Order
    exportDetailByOrder: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/exportDetailByOrder/${id}`,
          getHeader("GET")
        );
        // console.log(result);
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    checkAllowanceAutoFullfill: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/checkAllowanceAutoFullfill`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getPaymentDetail: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/getPaymentDetail/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Product = {
    getReport: async ({ effectiveDate, percent = 100 }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/product/getReport`,
          getHeader("POST", {
            effectiveDate,
            percent,
          })
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    getCurrentCommissionRateTable: async ({ id, date }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/product/getCurrentCommissionRateTable`,
          getHeader("POST", {
            date,
            id,
          })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        throw error;
      }
    },
  };

  const Allowance = {
    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/allowance/getStatSummary`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    terminate: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/allowance/terminate/${id}`,
          getHeader("GET")
        );
        result = await result.json();

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    reactive: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/allowance/reactive/${id}`,
          getHeader("GET")
        );
        result = await result.json();

        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const getActivityLog = async (entityId, commentOnly = false) => {
    try {
      if (!entityId) return null;
      const apiEntity = "activityLog";
      const pageSize = 10;
      const currentPage = 1;
      // const sort  ={ by:'createdAt', order:'desc'}
      // const searchQuery = { entityId : '62f4bc38cf33e13de29c6e84' }
      const searchQuery = commentOnly
        ? { entityId, type: "COMMENT" }
        : { entityId };
      let result = await search({
        apiEntity,
        pageSize,
        currentPage,
        searchQuery,
      });
      // console.log(result)
      return result.docs;
    } catch (error) {
      return null;
    }
  };
  const addActivityLog = async ({ entityId, title, by }) => {
    try {
      if (!entityId) return null;
      const values = {
        entityId,
        title,
        by,
      };
      let result = await add({ apiEntity: "activityLog", values });
      return result;
    } catch (error) {
      return null;
    }
  };

  const addCommentLog = async ({ entityId, title, by }) => {
    try {
      if (!entityId) return null;
      const values = {
        entityId,
        type: "COMMENT",
        title,
        by,
      };
      let result = await add({ apiEntity: "activityLog", values });
      return result;
    } catch (error) {
      return null;
    }
  };
  const Provider = {
    getPortalReport: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/provider/getPortalReport`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    getPublicLogin: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/provider/getPublicLogin/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    getContactList: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/provider/getContactList/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const BankTransaction = {
    unMatch: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/bankTransaction/unMatch/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  // return [searchApi, addApi, deleteApi ]
  const api = {
    Client,
    Order,
    OrderService,
    User,
    CommissionStatement,
    CommissionPayment,
    Payroll,
    PayrollPayment,
    Allowance,
    Product,
    Provider,
    Wallet,
    Bid,
    ClientPayment,
    Ticket,
    DataFeed,
    OrderProfolio,
    CalendarEvent,
    ConsultantIncentive,
    ClientPromotion,
    TopRanking,
    Quotation,
    DebitNote,
    CreditNote,
    Cpd,
    QuoRequest,
    Renewal,
    GIMatchPayment,
    GISettlement,
    Provider,
    BankAccount,
    BankTransaction,
    search,
    aggregate,
    add,
    copyById,
    getDistinctValues,
    removeById,
    getById,
    updateById,
    getErrorMsg,
    uploadFile,
    downloadZipFiles,
    importBatch,

    getActivityLog,
    addCommentLog,
    getOverridingResult,
    addActivityLog,
    renameFile,
  };
  return [api];
};
